import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import ListSelect from "./listSelect";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { setISOWeek } from "date-fns";

export default function FilterSection(props) {
  function filterValues() {
    let data = {};
    if (country != null) {
      data.country = country;
    }

    if (brand != null) {
      data.brand = brand;
    }

    if (quarter != null) {
      data.quarter = quarter;
    }

    if (owner != null) {
      data.owner = owner;
    }

    console.log(data);
    props.onFilter(data);
  }

  function resetFilter() {
    setCountry(null);
    setBrand(null);
    setQuarter(null);
    setOwner(null);
    setFilter(false);
    props.onResetFilter();
  }

  const [country, setCountry] = useState(null);
  const [brand, setBrand] = useState(null);
  const [quarter, setQuarter] = useState(null);
  const [owner, setOwner] = useState(null);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    if (country != null || brand != null || quarter != null || owner != null) {
      setFilter(true);
    } else {
      setFilter(false);
    }
  }, [country, brand, quarter, owner]);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        m: 1,
        p: 1,
        width: "100%",
        // height: 100,
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{ width: "100%" }}
      >
        Filter options
      </Typography>
      <Paper sx={{ backgroundColor: "#e8e8e8", width: "100%", p: 1, pb: 2 }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={3}>
            <ListSelect
              list="country"
              label="Country"
              value={country}
              onChange={(value) => setCountry(value)}
            ></ListSelect>
          </Grid>
          <Grid item xs={2}>
            <ListSelect
              list="brand"
              label="Brand"
              value={brand}
              onChange={(value) => setBrand(value)}
            ></ListSelect>
          </Grid>
          <Grid item xs={2}>
            <ListSelect
              list="quarter"
              label="Quarter"
              value={quarter}
              onChange={(value) => setQuarter(value)}
            ></ListSelect>
          </Grid>
          <Grid item xs={3}>
            <ListSelect
              list={["admin@admin.com", "user@user.com"]}
              label="User"
              value={owner}
              onChange={(value) => setOwner(value)}
            ></ListSelect>
          </Grid>

          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
              sx={{ marginRight: "8px" }}
              onClick={filterValues}
            >
              Filter
            </Button>
            <Button
              disabled={filter ? false : true}
              size="small"
              color="primary"
              variant="contained"
              onClick={resetFilter}
              //   sx={{ marginRight: "8px" }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
