import React, { useState, useEffect } from "react";
import Nav from "../components/nav";
import { Link } from "react-router-dom";
import firebaseDb from "../firebase";

import Container from "@mui/material/Container";
import "./DashboardPage.css";

import chartService from "../services/chart.service";
import FilterSection from "../components/filterSection";

import { ListContext } from "../services/ListContext";
import ChartCard from "../components/ChatCard";

const DashboardPage = () => {
  var { ready } = React.useContext(ListContext);

  const [charts, setCharts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // console.log();

    chartService.getCharts().then((res) => {
      // console.log(res);

      setCharts(res);
      setIsLoading(false);
    });
  }, []);

  const handleFilter = (filter) => {
    setIsLoading(true);
    chartService.getCharts(filter).then((res) => {
      // console.log(res);

      setCharts(res);
      setIsLoading(false);
    });
  };

  const handleResetFilter = () => {
    setIsLoading(true);
    chartService.getCharts().then((res) => {
      // console.log(res);
      setCharts(res);
      setIsLoading(false);
    });
  };

  const deleteFile = (file) => {
    console.log("delete now", file);

    chartService.deleteChart(file).then((res) => {
      setCharts(charts.filter((x) => x._id !== file));
    });
  };

  const cloneFile = (file) => {
    console.log("clone now", file);

    chartService.cloneChart(file).then((res) => {
      setCharts((charts) => [...charts, res]);
    });
  };

  if (!ready) return <div>Loading</div>;

  return (
    <>
      <Nav></Nav>

      <main>
        <Container component="section" maxWidth="xl">
          <FilterSection
            onFilter={handleFilter}
            onResetFilter={handleResetFilter}
          ></FilterSection>
          {isLoading && <h1 style={{ textAlign: "center" }}>Loading...</h1>}
          {/* hello */}
          {/* <div className="app-card-list" id="app-card-list"> */}
          {Object.keys(charts).map((id, index) => (
            <ChartCard
              key={id}
              index={index}
              details={charts[id]}
              id={id}
              onDelete={deleteFile}
              onClone={cloneFile}
            />
          ))}
          {/* </div> */}
        </Container>
      </main>
    </>
  );
};

export default DashboardPage;
