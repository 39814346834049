import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { FaList } from "react-icons/fa";
import Button from "@mui/material/Button";
import { FaSave, FaCloudDownloadAlt } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";

import ChartButtons from "./chartButtons";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";

const NavWrapper = styled.nav`
  background-color: #00013a;
  color: white;
  display: flex;
  flex-direction: row;
  padding: 12px;
  width: 100%;
  height: 70px;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  justify-content: space-between;
`;

export default function Nav(props) {
  const location = useLocation();
  let navigate = useNavigate();
  // console.log(props);

  const [user, setUser] = useState({});
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    let user = AuthService.getCurrentUser();
    if (user) {
      setUser(user);
      setAuth(true);
    } else {
      logout();
    }
  }, []);

  const logout = () => {
    AuthService.logout();
    navigate("/");
  };

  return (
    <NavWrapper>
      <div
        className="navLeft"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Link
          to="/dashboard"
          style={{
            textDecoration: "none",
            color: "black",
            margin: "0 16px",
            display: "flex",
            color: "#ffffff",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <FaList />
        </Link>
        Customer Experience Flow ({user.user?.role}){" "}
        <Button
          sx={{ ml: 2 }}
          variant="outlined"
          color="secondary"
          size="small"
          onClick={logout}
          startIcon={<FiLogOut />}
        >
          Logout
        </Button>
      </div>
      {(location.pathname === "/dashboard" || location.pathname === "/") && (
        <div className="navRight">
          <Button
            component={Link}
            to="/new"
            color="secondary"
            variant="outlined"
          >
            Create New
          </Button>
        </div>
      )}

      {location.pathname === "/edit" && (
        <>
          <ChartButtons onDragStart={props.onDragStart}></ChartButtons>

          <div className="navRight">
            <Button
              onClick={props.onDownload}
              color="secondary"
              variant="outlined"
              sx={{ marginRight: "8px" }}
            >
              <FaCloudDownloadAlt
                style={{ height: "1.5em", width: "1.5em", marginRight: "8px" }}
              />{" "}
              Download
            </Button>
            <Button onClick={props.onSave} color="secondary" variant="outlined">
              <FaSave
                style={{ height: "1.5em", width: "1.5em", marginRight: "8px" }}
              />{" "}
              Save
            </Button>
          </div>
        </>
      )}

      {location.pathname === "/new" && (
        <div className="navRight">
          <Button
            onClick={props.onCreate}
            color="secondary"
            variant="outlined"
            sx={{ marginRight: "8px" }}
            disabled={!props.ready}
          >
            Create Project
          </Button>
        </div>
      )}
    </NavWrapper>
  );
}
