import React, { useState, useEffect, useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  Controls,
  ControlButton,
  Background,
  useZoomPanHelper,
  isNode,
  isEdge,
  ArrowHeadType,
} from "react-flow-renderer";

import { useLocation } from "react-router-dom";

import "../styles/default.css";

import firebaseDb from "../firebase";
import chartService from "../services/chart.service";

import * as htmlToImage from "html-to-image";
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { jsPDF } from "jspdf";

import Nav from "../components/nav";

import nodeBackground from "../components/nodes/nodeBackground";
import nodeWorkflow from "../components/nodes/nodeWorkflow";
import nodeIcons from "../components/nodes/nodeIcons";
import nodeMockupPhone from "../components/nodes/nodeMockupPhone";
import nodeMockupTablet from "../components/nodes/nodeMockupTablet";
import nodeMockupLaptop from "../components/nodes/nodeMockupLaptop";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Autocomplete from "@mui/material/Autocomplete";
import { ColorPicker } from "material-ui-color";

import IconSelect from "../components/iconSelect";
import ListSelect from "../components/listSelect";
import { set } from "date-fns";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

const nodeTypes = {
  background: nodeBackground,
  workflow: nodeWorkflow,
  icons: nodeIcons,
  mockupPhone: nodeMockupPhone,
  mockupTablet: nodeMockupTablet,
  mockupLaptop: nodeMockupLaptop,
};

const componentStyles = { width: 40, border: "none", padding: 0 };

const onDragStart = (event, nodeType, url) => {
  event.dataTransfer.setData("application/reactflow", nodeType);
  event.dataTransfer.setData("url", url);
  event.dataTransfer.effectAllowed = "move";
};

function onDragOver(event) {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
}

let id = 0;
const getId = () => `abv_${id++}`;
const background_element = {
  id: getId(),
  type: "background",
  data: { label: "Background Node" },
  position: { x: 0, y: 0 },
  draggable: false,
  selectable: false,
  connectable: false,
  // style: { zIndex: 0 },
};

let initialElements = [background_element];

const DnDFlow = () => {
  var location = useLocation();

  const elemen = JSON.parse(location.state.chartData);
  const projectId = location.state.id;
  const projectName = location.state.name;

  const [tab, setTab] = React.useState(0);

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const downloadJson = (content, fileName, contentType) => {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  };

  const exportJson = () => {
    if (window.confirm("Download the file Now")) {
      downloadJson(JSON.stringify(elements), "data.json", "text/plain");
    }
  };

  const updateFile = async () => {
    // console.log(projectName);
    const thumb = await htmlToImage.toJpeg(
      document.getElementById("abv_wrapper"),
      {
        quality: 0.8,
        backgroundColor: "#FFFFFF",
        canvasWidth: 320,
        canvasHeight: 180,
        // width: 320,
        // height: 180,
      }
    );

    const flow = reactFlowInstance.toObject();
    console.log(flow.elements);

    var data = {
      name: p_name,
      _id: projectId,
      country: p_country,
      brand: p_brand,
      indication: p_indication,
      segment: p_segment,
      target: p_target,
      quarter: p_quarter,
      values: JSON.stringify(flow.elements),
      date: new Date().getTime(),
      thumbnail: thumb,
    };
    if (projectId === undefined) {
      console.log("error");
    } else {
      // firebaseDb.child(`charts/${projectId}`).set(data, (err) => {
      //   if (err) {
      //     console.log(err);
      //   } else {
      //     alert("File has been updated successfully");
      //   }
      // });

      chartService.updateChart(data).then((res) => {
        alert("File has been updated successfully");
      });
    }
  };

  const onSave = async () => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();
      // localStorage.setItem("abv", JSON.stringify(flow));

      const thumb = await htmlToImage.toJpeg(
        document.getElementById("abv_wrapper"),
        {
          quality: 0.8,
          backgroundColor: "#FFFFFF",
          canvasWidth: 320,
          canvasHeight: 180,
          // width: 320,
          // height: 180,
        }
      );

      var data = {
        name: "ABBVIE" + new Date().getTime(),
        values: JSON.stringify(flow.elements),
        date: new Date().getTime(),
        thumbnail: thumb,
      };

      firebaseDb
        .child("charts")
        .push(data, (err) => {
          if (err) {
            console.log(err);
          } else {
            alert("File has been saved successfully");
          }
        })
        .then((snap) => {
          const key = snap.key;
          console.log("key", key);
        });
    }
  };

  const onRestore = () => {
    const restoreFlow = async () => {
      const flow = await JSON.parse(localStorage.getItem("abv"));

      if (flow) {
        setElements((s) => []);
        setElements((s) => flow.elements);

        //fix id
        id = flow.elements.length;

        console.log(flow.elements);
        fitBounds();
      }
    };

    restoreFlow();
  };

  const handleThumbnail = async () => {
    const canvas = await htmlToImage.toJpeg(
      document.getElementById("abv_wrapper"),
      {
        quality: 0.8,
        backgroundColor: "#FFFFFF",
      }
    );
    console.log(canvas);
  };

  const handleDownload = () => {
    setShowControls(false);
    fitBounds();

    var background = document.getElementById("abv_bg");
    background.style.opacity = "1";

    var canvas = document.getElementById("abv_main");
    canvas.style.border = "2px solid white";

    htmlToImage
      .toPng(document.getElementById("abv_wrapper"), {
        quality: 0.9,
      })
      .then(function (dataUrl) {
        // var link = document.createElement("a");
        // link.download = "chart.jpeg";
        // link.href = dataUrl;
        // link.click();
        const pdf = new jsPDF({
          orientation: "landscape",
          compress: true,
        });
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(dataUrl, "png", 0, 0, pdfWidth, pdfHeight, "SLOW");
        pdf.save("chart.pdf");
        setShowControls(true);
        background.style.opacity = "0.7";
        canvas.style.border = "2px solid grey";
      });

    close();
  };

  const [reactFlowInstance, setReactFlowInstance] = useState();
  const [elements, setElements] = useState(elemen);
  const [selectedNode, setSelectedNode] = useState(null);
  const [showControls, setShowControls] = useState(true);

  const [p_name, set_p_name] = useState(location.state.name);
  const [p_country, set_p_country] = useState(location.state.country);
  const [p_brand, set_p_brand] = useState(location.state.brand);
  const [p_indication, set_p_indication] = useState(location.state.indication);
  const [p_segment, set_p_segment] = useState(location.state.segment);
  const [p_target, set_p_target] = useState(location.state.target);
  const [p_quarter, set_p_quarter] = useState(location.state.quarter);

  const [nodeX, setNodeX] = useState("");
  const [nodeY, setNodeY] = useState("");
  const [nodeBg, setNodeBg] = useState("#ffffff");
  const [radius, setRadius] = useState("");
  const [label, setLabel] = useState("");
  const [nodeDate, setNodeDate] = useState(null);
  const [nodeCycle, setNodeCycle] = useState(null);
  const [nodeBrand, setNodeBrand] = useState(null);
  const [nodeIndication, setNodeIndication] = useState(null);
  const [nodeFunctions, setNodeFunctions] = useState([]);
  const [nodeBudget, setNodeBudget] = useState("");
  const [nodeMapNumber, setNodeMapNumber] = useState(null);
  const [nodeNotes, setNodeNotes] = useState(null);
  const [nodeImage, setNodeImage] = useState("");
  const [nodeImageLoading, setNodeImageLoading] = useState(false);

  const [nodeIcon, setNodeIcon] = useState({
    label: "AbbVie Care Services Booklet",
    text: "AbbVie Care Services Booklet",
    category: "Abbvie Care",
    code: "AbbVie%20Care%20Services%20Booklet.png",
  });

  const onConnect = (params) =>
    setElements((els) =>
      addEdge(
        {
          ...params,
          animated: false,
          arrowHeadType: ArrowHeadType.Arrow,
          type: "smoothstep",
          style: { stroke: "#4d6491", strokeWidth: "4px" },
          // label: "edge label",
        },
        els
      )
    );
  const onElementsRemove = (elementsToRemove) => {
    setElements((els) => removeElements(elementsToRemove, els));
    setSelectedNode(null);
  };
  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const fitBounds = (event) => {
    reactFlowInstance.setTransform({ x: 0, y: 0, zoom: 0.5 });
  };

  const onDrop = (event) => {
    event.preventDefault();
    // const url = event.dataTransfer.getData("url");

    const type = event.dataTransfer.getData("application/reactflow");

    if (reactFlowInstance) {
      const position = reactFlowInstance.project({
        x: event.clientX - 20,
        y: event.clientY - 90,
      });

      const newNode2 = {
        id: makeid(5) + "_" + (elements.length + 1).toString(),
        type: type,
        // style: componentStyles,
        data: {
          label: "Key Msg:",
          backgroundColor: "#999094",
          color: "#999094",
          background: "#ffffff",
          radius: 8,
          icon: nodeIcon,
          source: "",
          date: null,
          cycle: null,
          brand: null,
          indication: null,
          functions: [],
          budget: "",
          mapnumber: "",
          notes: "",
        },
        sourcePosition: "left",
        targetPosition: "right",
        position,
      };

      setElements((es) => es.concat(newNode2));
    }
  };

  const onDragEnd = (event, element) => {
    console.log("onDragEnd", element);
    setSelectedNode(element);
    setNodeX(element.position.x);
    setNodeY(element.position.y);
    setNodeBg(element.data.background);
    setRadius(element.data.radius);
    setLabel(element.data.label);
    setNodeImage(element.data.source);
    setNodeIcon(element.data.icon);
    setNodeDate(element.data.date);
    setNodeCycle(element.data.cycle);
    setNodeBrand(element.data.brand);
    setNodeIndication(element.data.indication);
    setNodeFunctions(element.data.functions);
    setNodeBudget(element.data.budget);
    setNodeMapNumber(element.data.mapnumber);
    setNodeNotes(element.data.notes);
  };

  const onElementClick = (event, element) => {
    // if (isEdge) console.log("onEdgeClick", element);
    if (isNode(element)) {
      // console.log("onElementClick", element);

      if (element.type === "background") return;

      setSelectedNode(element);
      setNodeX(element.position.x);
      setNodeY(element.position.y);
      setNodeBg(element.data.background);
      setRadius(element.data.radius);
      setLabel(element.data.label);
      setNodeImage(element.data.source);
      setNodeIcon(element.data.icon);
      setNodeDate(element.data.date);
      setNodeCycle(element.data.cycle);
      setNodeBrand(element.data.brand);
      setNodeIndication(element.data.indication);
      setNodeFunctions(element.data.functions);
      setNodeBudget(element.data.budget);
      setNodeMapNumber(element.data.mapnumber);
      setNodeNotes(element.data.notes);
    }
  };

  const onIconChange = (event) => {
    console.log("parent", event);
    // setNodeImage(event.source);
    setNodeIcon(event);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      setNodeImageLoading(true);

      formData.append("image", event.target.files[0]);
      try {
        fetch(process.env.REACT_APP_IMAGE_POST_URL, {
          // Your POST endpoint
          method: "POST",
          body: formData, // This is your file object
        })
          .then(
            (response) => response.json() // if the response is a JSON object
          )
          .then(
            (success) => {
              console.log(success);
              setNodeImage(success.image);
              setNodeImageLoading(false);
            } // Handle the success response object
          )
          .catch(
            (error) => console.log(error) // Handle the error response object
          );
      } catch (e) {
        console.log(e);
      }

      // let reader = new FileReader();
      // reader.onload = (e) => {
      //   // setNodeImage(e.target.result);

      // };
      // reader.readAsDataURL(event.target.files[0]);
    }
  };

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selectedNode?.id) {
          el.data = {
            ...el.data,
            label: label,
          };
        }
        return el;
      })
    );
  }, [label, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selectedNode?.id) {
          el.data = {
            ...el.data,
            radius: radius,
          };
        }
        return el;
      })
    );
  }, [radius, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selectedNode?.id) {
          el.data = {
            ...el.data,
            background: nodeBg,
          };
        }
        return el;
      })
    );
  }, [nodeBg, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selectedNode?.id) {
          el.data = {
            ...el.data,
            source: nodeImage,
          };
        }

        return el;
      })
    );
  }, [nodeImage, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selectedNode?.id) {
          el.data = {
            ...el.data,
            icon: nodeIcon,
          };
        }

        return el;
      })
    );
  }, [nodeIcon, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selectedNode?.id) {
          el.data = {
            ...el.data,
            date: nodeDate,
          };
        }

        return el;
      })
    );
  }, [nodeDate, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selectedNode?.id) {
          el.data = {
            ...el.data,
            cycle: nodeCycle,
          };
        }

        return el;
      })
    );
  }, [nodeCycle, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selectedNode?.id) {
          el.data = {
            ...el.data,
            brand: nodeBrand,
          };
        }

        return el;
      })
    );
  }, [nodeBrand, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selectedNode?.id) {
          el.data = {
            ...el.data,
            indication: nodeIndication,
          };
        }

        return el;
      })
    );
  }, [nodeIndication, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selectedNode?.id) {
          el.data = {
            ...el.data,
            functions: nodeFunctions,
          };
        }

        return el;
      })
    );
  }, [nodeFunctions, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selectedNode?.id) {
          el.data = {
            ...el.data,
            budget: nodeBudget,
          };
        }

        return el;
      })
    );
  }, [nodeBudget, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selectedNode?.id) {
          el.data = {
            ...el.data,
            mapnumber: nodeMapNumber,
          };
        }

        return el;
      })
    );
  }, [nodeMapNumber, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === selectedNode?.id) {
          el.data = {
            ...el.data,
            notes: nodeNotes,
          };
        }

        return el;
      })
    );
  }, [nodeNotes, setElements]);

  return (
    <>
      <Nav
        onSave={updateFile}
        onDownload={handleDownload}
        onDragStart={onDragStart}
      ></Nav>

      <div className="abbvieWrap">
        <ReactFlowProvider>
          <div className="reactflow-wrapper" id="abv_wrapper">
            <ReactFlow
              id="abv_main"
              style={{
                height: "720px",
                width: "1280px",
                border: "2px solid grey",
                borderRadius: "10px",
                margin: "5px",
              }}
              defaultZoom="0.5"
              minZoom="0.5"
              maxZoom="4"
              connectionLineStyle={{ stroke: "#4d6491", strokeWidth: "3px" }}
              edgeTypes={{ animated: false }}
              elements={elements}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              onElementsRemove={onElementsRemove}
              onLoad={onLoad}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onNodeDragStop={onDragEnd}
              onElementClick={onElementClick}
              onPaneClick={(e) => setSelectedNode(null)}
            >
              {showControls && (
                <>
                  <Controls showFitView={false}>
                    <ControlButton onClick={() => fitBounds()}>
                      All
                    </ControlButton>
                  </Controls>
                  <Background variant="lines" gap={16} size={1} />
                </>
              )}
            </ReactFlow>
          </div>

          <Box sx={{ width: "100%", height: "calc(100% - 210px)" }}>
            <Tabs
              value={tab}
              onChange={handleTab}
              textColor="primary"
              indicatorColor="primary"
              variant="fullWidth"
            >
              <Tab label="General Information" />
              <Tab label="Advanced Information" />
              <Tab label="Project Information" />
            </Tabs>
            <Box sx={{ p: 1 }}>
              <TabPanel value={tab} index={0}>
                {!selectedNode && (
                  <div className="propsPlaceholder">
                    Select an item to view/edit properties
                  </div>
                )}
                {selectedNode && (
                  <>
                    <div className="position" style={{ color: " #9a9a9a" }}>
                      Selected node: {selectedNode?.id} |
                      <label> Pos X: {Math.trunc(nodeX)}</label> /{" "}
                      <label>Pos Y: {Math.trunc(nodeY)}</label>{" "}
                    </div>

                    {/* other stuff */}
                    <Grid container spacing={3} alignItems="stretch">
                      <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Date"
                            value={nodeDate}
                            onChange={(newValue) => {
                              setNodeDate(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField fullWidth sx={{ mt: 2 }} {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6}>
                        <ListSelect
                          list={[
                            "C1 2022",
                            "C2 2022",
                            "C3 2022",
                            "C4 2022",
                            "C1 2023",
                            "C2 2023",
                            "C3 2023",
                            "C4 2023",
                          ]}
                          label="Cycle"
                          value={nodeCycle}
                          onChange={(value) => setNodeCycle(value)}
                        ></ListSelect>
                      </Grid>
                    </Grid>
                    <ListSelect
                      list="brand"
                      label="Product"
                      value={nodeBrand}
                      onChange={(value) => {
                        setNodeIndication(null);
                        setNodeBrand(value);
                      }}
                    ></ListSelect>
                    <ListSelect
                      list="indication"
                      label="Indication"
                      brand={nodeBrand}
                      disabled={nodeBrand ? false : true}
                      value={nodeIndication}
                      onChange={(value) => setNodeIndication(value)}
                    ></ListSelect>
                    <TextField
                      id="outlined-multiline-static"
                      label="Key Messages"
                      multiline
                      rows={3}
                      fullWidth
                      sx={{ mt: 2 }}
                      value={label || ""}
                      onChange={(evt) => setLabel(evt.target.value)}
                    />

                    {(selectedNode.type === "mockupPhone" ||
                      selectedNode.type === "mockupTablet" ||
                      selectedNode.type === "mockupLaptop") && (
                      <div className="ImagePicker">
                        <input
                          accept="image/*"
                          type="file"
                          id="select-image"
                          style={{ display: "none" }}
                          onChange={onImageChange}
                        />
                        <label htmlFor="select-image">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            size="small"
                          >
                            Upload Image
                          </Button>
                        </label>
                        {nodeImageLoading && (
                          <div>Loading image. Please wait</div>
                        )}
                        {nodeImage && (
                          <img
                            src={`http://51.75.95.195/images/${nodeImage}`}
                            // width="150"
                            width="320"
                            alt="img"
                          />
                        )}
                      </div>
                    )}
                    {(selectedNode.type === "workflow" ||
                      selectedNode.type === "icons") && (
                      <>
                        <br />
                        <br />
                        <IconSelect
                          onIconChange={onIconChange}
                          selected={nodeIcon}
                        ></IconSelect>
                      </>
                    )}

                    {selectedNode.type === "workflow" && (
                      <Grid container spacing={3} alignItems="stretch">
                        <Grid item xs={4}>
                          <TextField
                            id="radius"
                            label="Border Radius"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            sx={{ mt: 2 }}
                            size="small"
                            value={radius || ""}
                            onChange={(evt) => setRadius(evt.target.value)}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <div className="colorPicker">
                            <div style={{ marginRight: 4 }}>
                              Background Color
                            </div>
                            <ColorPicker
                              defaultValue="transparent"
                              value={nodeBg}
                              hideTextfield
                              onChange={(value) => setNodeBg(`#${value.hex}`)}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </TabPanel>
              <TabPanel value={tab} index={1}>
                {!selectedNode && (
                  <div className="propsPlaceholder">
                    Select an item to view/edit properties
                  </div>
                )}
                {selectedNode && (
                  <>
                    <div
                      className="position"
                      style={{ color: " #9a9a9a", marginBottom: 8 }}
                    >
                      Selected node: {selectedNode?.id} |
                      <label> Pos X: {Math.trunc(nodeX)}</label> /{" "}
                      <label>Pos Y: {Math.trunc(nodeY)}</label>{" "}
                    </div>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      freeSolo
                      value={nodeFunctions}
                      filterSelectedOptions
                      options={[
                        "Commercial",
                        "Medical",
                        "Regulatory",
                        "BTS",
                        "CEx",
                      ]}
                      onChange={(e, attr) => {
                        setNodeFunctions(attr);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Functions involved"
                          // multiline
                          fullWidth
                          sx={{ mt: 1, mb: 2 }}
                        ></TextField>
                      )}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Budget"
                      variant="outlined"
                      fullWidth
                      value={nodeBudget}
                      onChange={(e) => setNodeBudget(e.target.value)}
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="MAP number"
                      variant="outlined"
                      fullWidth
                      value={nodeMapNumber}
                      onChange={(e) => setNodeMapNumber(e.target.value)}
                      sx={{ mb: 2 }}
                    />

                    <TextField
                      id="outlined-multiline-static"
                      label="Other Notes"
                      multiline
                      rows={4}
                      fullWidth
                      value={nodeNotes}
                      onChange={(e) => setNodeNotes(e.target.value)}
                      sx={{ mt: 2, mb: 1 }}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <TextField
                  id="outlined-basic"
                  label="Project Name"
                  variant="outlined"
                  fullWidth
                  value={p_name}
                  onChange={(e) => set_p_name(e.target.value)}
                  sx={{ mt: 2 }}
                />
                <ListSelect
                  list="country"
                  label="Country"
                  value={p_country}
                  onChange={(value) => set_p_country(value)}
                ></ListSelect>

                <ListSelect
                  list="brand"
                  label="Product"
                  value={p_brand}
                  onChange={(value) => {
                    set_p_indication(null);
                    set_p_brand(value);
                  }}
                ></ListSelect>
                <ListSelect
                  list="indication"
                  label="Indication"
                  brand={p_brand}
                  disabled={p_brand ? false : true}
                  value={p_indication}
                  onChange={(value) => set_p_indication(value)}
                ></ListSelect>
                <ListSelect
                  list="segment"
                  label="Segment"
                  value={p_segment}
                  onChange={(value) => set_p_segment(value)}
                ></ListSelect>
                <ListSelect
                  list="target"
                  label="Target"
                  value={p_target}
                  onChange={(value) => set_p_target(value)}
                ></ListSelect>

                <ListSelect
                  list="quarter"
                  label="Quarter"
                  value={p_quarter}
                  onChange={(value) => set_p_quarter(value)}
                ></ListSelect>
              </TabPanel>
            </Box>
          </Box>
        </ReactFlowProvider>
      </div>
    </>
  );
};

export default DnDFlow;
