import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";

const nodeButtons = [
  { name: "Icons", type: "icons", icon: "" },
  { name: "Workflow", type: "workflow", icon: "" },
  { name: "Phone", type: "mockupPhone", icon: "" },
  { name: "Tablet", type: "mockupTablet", icon: "" },
  { name: "Laptop", type: "mockupLaptop", icon: "" },
];

export default function ChartButtons(props) {
  const SidebarElement = ({ but, onDragStart }) => (
    // <div
    //   style={{
    //     maxWidth: "150px",
    //     minWidth: "100px",
    //     margin: "5px",
    //     padding: 5,
    //     border: "1px solid white",
    //     // borderRadius: 5,
    //     background: "#white",
    //     color: "#fff",
    //     textAlign: "center",
    //   }}
    //   onDragStart={(event) => onDragStart(event, but.type, but.name)}
    //   draggable
    // >
    //   {but.name}
    //   {/* <img src={url} alt="screen" draggable="false" /> */}
    // </div>

    <Button
      onClick={props.onDownload}
      color="secondary"
      variant="outlined"
      sx={{ marginRight: "8px", borderRadius: 0 }}
      onDragStart={(event) => onDragStart(event, but.type, but.name)}
      draggable
    >
      {but.name}
    </Button>
  );

  return (
    <div className="navCenter" style={{ display: "flex" }}>
      {nodeButtons.map((but, i) => (
        <SidebarElement key={i} but={but} onDragStart={props.onDragStart} />
      ))}
    </div>
  );
}
