import React, { memo } from "react";
import styled from "styled-components";

import { Handle } from "react-flow-renderer";

import tablet from "../../images/mockups/tablet.png";

const NodeWrapper = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MarkupWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 100px;
  overflow: hidden;
  border-radius: 10px;
  .image {
    position: absolute;
    width: 100%;
  }
  .source {
    z-index: 0;
    background: #ffffff;
    /* width: auto;
    height: 100%;
    left: -125%; */
    height: 92%;
  }

  .cover {
    z-index: 1;
    width: 150px;
  }
`;

const TextLabel = styled.div`
  text-align: center;
  width: 170px;
  color: #3d3a3b;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.1px;
  white-space: pre-line;
  font-weight: 500;
`;

export default memo(({ data }) => {
  //   console.log("data", data);
  const handleStyle = {
    backgroundColor: "#fff",
    width: 22,
    height: 22,
    borderRadius: 12,
    border: `1px solid #999094`,
    backgroundColor: "#e8e8e8",
  };

  return (
    <NodeWrapper>
      <Handle
        type="target"
        position="left"
        style={{
          ...handleStyle,
          left: -22,
        }}
        onConnect={(params) => console.log("handle onConnect", params)}
      />

      <MarkupWrapper>
        {data.source && (
          <img
            src={`${process.env.REACT_APP_IMAGE_PUBLIC_URL}${data.source}`}
            alt="source"
            draggable="false"
            className="source image"
          />
        )}

        <img
          src={tablet}
          className="cover image"
          alt="tablet"
          draggable="false"
        />
      </MarkupWrapper>
      <TextLabel>{data.label}</TextLabel>

      <Handle
        type="source"
        position="right"
        id="a"
        style={{
          ...handleStyle,
          right: -22,
        }}
      />
    </NodeWrapper>
  );
});
