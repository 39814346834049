import React, { memo } from "react";

import bgpic from "../../images/bg2x.png";

export default memo(({ data }) => {
  return (
    <>
      <div>
        <img
          src={`https://s1.myovn.com/abbvie/bg/${data.source}`}
          id="abv_bg"
          style={{
            width: "200%",
            height: "200%",
            opacity: "1",
            pointerEvents: "none",
            zIndex: 1,
          }}
          alt="i"
        />
      </div>
    </>
  );
});
