import React, { Component } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Link } from "react-router-dom";

export default function ChartCard(props) {
  let linkstate = {
    id: `${props.details._id}`,
    name: `${props.details.name}`,
    country: props.details.country,
    brand: props.details.brand,
    indication: props.details.indication,
    segment: props.details.segment,
    target: props.details.target,
    quarter: props.details.quarter,
    chartData: `${props.details.values}`,
  };

  var d = new Date(props.details.date);

  const cloneFile = (file) => {
    // console.log(file);
    props.onClone(file);
  };

  const deleteFile = (file) => {
    if (window.confirm("Are you sure to delete this project?")) {
      //   console.log(file);
      props.onDelete(file);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        m: 1,
        p: 1,
        width: "100%",
        // height: 100,
      }}
    >
      <Paper
        sx={{
          backgroundColor: "#00013a99",
          width: "100%",
          p: 2,
          mb: 1,
          color: "#ffffff",
        }}
      >
        <Grid
          container
          spacing={3}
          alignItems="flex-end"
          justifyContent="center"
          sx={{ position: "relative" }}
        >
          <Grid item xs={4}>
            <Link to="/edit" state={linkstate}>
              <img
                src={props.details.thumbnail}
                alt="chart"
                width="100%"
                style={{ borderRadius: 8 }}
              />
            </Link>
          </Grid>
          <Grid item xs={8}>
            <CardContent>
              <Grid
                container
                spacing={3}
                alignItems="flex-end"
                justifyContent="center"
              >
                <Grid item xs={8}>
                  <Typography
                    sx={{ fontSize: 12, position: "absolute", top: 25 }}
                    gutterBottom
                  >
                    Date: {d.toLocaleDateString()}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ mb: 6 }}
                  >
                    <span
                      className="cardField"
                      style={{
                        fontSize: 14,
                        paddingRight: 24,
                        maxHeight: 130,
                        overflow: "hidden",
                      }}
                    >
                      Name:
                    </span>
                    <br></br>
                    {props.details.name}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "1.2em", lineHeight: "2" }}
                  >
                    <span className="cardField">Country: </span>
                    {props.details.country}
                    <br />
                    <span className="cardField"> Product: </span>
                    {props.details.brand}
                    <br />
                    <span className="cardField">Indication:</span>{" "}
                    {props.details.indication}
                    <br />
                    <span className="cardField"> Period: </span>
                    {props.details.quarter}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>

            <CardActions
              sx={{
                borderTop: "1px solid #0f5aff",
                display: "flex",
                justifyContent: "flex-end",
                // position: "absolute",
                // bottom: 0,
              }}
            >
              <Button
                color="primary"
                variant="contained"
                startIcon={<VisibilityIcon />}
                component={Link}
                to="/edit"
                state={linkstate}
              >
                View / Edit
              </Button>

              <Button
                color="primary"
                variant="contained"
                startIcon={<ContentCopyIcon />}
                onClick={() => cloneFile(props.details)}
              >
                Clone Project
              </Button>

              <Button
                color="primary"
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => deleteFile(props.details._id)}
              >
                Delete
              </Button>
            </CardActions>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
