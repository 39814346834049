import * as React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";
import DashboardPage from "./pages/DashboardPage";
import DnDFlow from "./pages/EditChart";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CreateNew from "./pages/CreateNew";
import { UserProvider } from "./services/UserContext";
import { ListProvider } from "./services/ListContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00013a",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: 50,
          // "&.Mui-disabled": {
          //   opacity: 0.7,
          // },
        },
      },
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />

    <BrowserRouter>
      <UserProvider>
        <ListProvider>
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="edit" element={<DnDFlow />} />
            <Route path="new" element={<CreateNew />} />
          </Routes>
        </ListProvider>
      </UserProvider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
