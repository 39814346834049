import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;
class ChartService {
  async getAllCharts() {
    try {
      const response = await axios.get(API_URL + "charts/all", {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      console.log("error", error.response.data);
    }
  }

  async getCharts(filter) {
    // console.log(filter);
    const u = new URLSearchParams(filter).toString();
    console.log(u);
    const url = API_URL + "charts/?" + u;
    console.log(url);
    try {
      const response = await axios.get(url, {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      console.log("error", error.response.data);
    }
  }

  getChart(id) {
    return axios.get(API_URL + "charts" + id, { headers: authHeader() });
  }

  async createChart(data) {
    try {
      const response = await axios.post(API_URL + "charts/", data, {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      console.log("error", error.response.data);
    }
  }

  async updateChart(data) {
    console.log(data);
    try {
      const response = await axios.patch(API_URL + "charts/" + data._id, data, {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      console.log("error", error.response.data);
    }
  }

  async deleteChart(id) {
    try {
      const response = await axios.delete(API_URL + "charts/" + id, {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      console.log("error", error.response.data);
    }
  }

  async cloneChart(file) {
    console.log(file);
    const newFile = { ...file };
    delete newFile.id,
      delete newFile._id,
      (newFile.name = "Copy of " + newFile.name);

    // return newFile;
    try {
      const response = await axios.post(API_URL + "charts/", newFile, {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      console.log("error", error.response.data);
    }
  }
}
export default new ChartService();
