import firebase from "firebase";

let config = {
  apiKey: "AIzaSyDDNzWAGuAZiqRV-vicL7laUo77PxddwSY",
  authDomain: "abbvie-eb77b.firebaseapp.com",
  databaseURL:
    "https://abbvie-eb77b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "abbvie-eb77b",
  storageBucket: "abbvie-eb77b.appspot.com",
  messagingSenderId: "487502865780",
  appId: "1:487502865780:web:8e84a995f650782141b821",
  measurementId: "G-BPKTH5LH6V",
};

var fireDb = firebase.initializeApp(config);

export default fireDb.database().ref();

// import firebase from "firebase";

// let config = {
//   apiKey: "AIzaSyCyVtJVByttlqrgUXToRf7eGdT0kyVj3pY",
//   authDomain: "flow-chart-77eaa.firebaseapp.com",
//   projectId: "flow-chart-77eaa",
//   databaseURL: "https://flow-chart-77eaa-default-rtdb.firebaseio.com/",
//   storageBucket: "flow-chart-77eaa.appspot.com",
//   messagingSenderId: "825276871835",
//   appId: "1:825276871835:web:a8e4c174fba1778c32823e",
// };

// var fireDb = firebase.initializeApp(config);

// export default fireDb.database().ref();
