import React, { memo } from "react";
import styled from "styled-components";

import { Handle } from "react-flow-renderer";

const NodeWrapper = styled.div`
  width: 150px;
  background: transparent;

  padding: 4px 6px;
`;

const GlobalWrapper = styled.div`
  /* display: flex;
  flex-direction: row; */
`;

const TopWrapper = styled.div`
  text-transform: uppercase;
  color: #666;
  font-size: 8px;
  /* letter-spacing: 2px; */
  letter-spacing: 0.1px;
  line-height: 12px;
  text-align: center;
  font-weight: 500;
  max-width: 85%;
  margin: 0 auto;
`;
const LeftWrapper = styled.div`
  width: 100px;
  height: 100px;
  /* background: ${({ backgroundColor }) => backgroundColor}; */
  /* border-radius: 6px; */
  background: ${({ source }) => `url(${source}) no-repeat left top`};
  background-size: 180%;
  background-position: 50%;
  flex: 0 0 100px;
  margin: 0 auto;
`;

const RightWrapper = styled.div`
  text-align: center;

  .desc-main {
    color: #3d3a3b;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1px;
    white-space: pre-line;
    margin: 0;
    padding: 0;
    font-weight: 500;
  }
`;

export default memo(({ data }) => {
  //   console.log("data", data);
  const handleStyle = {
    backgroundColor: "#fff",
    backgroundColor: "#e8e8e8",
    width: 22,
    height: 22,
    borderRadius: 12,
    border: `1px solid ${data.color}`,
  };

  return (
    <NodeWrapper radius={data.radius} background={data.background}>
      <Handle
        type="target"
        position="left"
        style={{
          ...handleStyle,

          left: -11,
        }}
        onConnect={(params) => console.log("handle onConnect", params)}
      />
      <GlobalWrapper>
        <TopWrapper>{data.icon?.text}</TopWrapper>
        <LeftWrapper
          backgroundColor={data.backgroundColor}
          source={`https://s1.myovn.com/abbvie/icons/${data.icon?.code}`}
        ></LeftWrapper>
        <RightWrapper accentColor={data.color}>
          <p className="desc-main">{data.label}</p>
        </RightWrapper>
      </GlobalWrapper>

      <Handle
        type="source"
        position="right"
        id="a"
        style={{
          ...handleStyle,

          right: -11,
        }}
      />
    </NodeWrapper>
  );
});
