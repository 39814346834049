import React, { useEffect, useState } from "react";
import Nav from "../components/nav";
import { Link, Navigate } from "react-router-dom";

import Box from "@mui/material/Box";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import BackgroundSelect from "../components/bgSelect";
import Autocomplete from "@mui/material/Autocomplete";

import * as htmlToImage from "html-to-image";

import chartService from "../services/chart.service";
import ListSelect from "../components/listSelect";

const CreateNew = () => {
  const [redirect, setRedirect] = useState(false);
  const [name, setName] = useState("");
  const [chart, setChart] = useState({});
  const [country, setCountry] = useState(null);
  const [brand, setBrand] = useState(null);
  const [indication, setIndication] = useState(null);
  const [segment, setSegment] = useState(null);
  const [target, setTarget] = useState(null);
  const [quarter, setQuarter] = useState(null);

  //enable create button
  const [ready, setReady] = useState(false);

  //check if we enable the create button
  useEffect(() => {
    if (
      name != "" &&
      country != null &&
      brand != null &&
      indication != null &&
      segment != null &&
      target != null &&
      quarter != null
    ) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [name, chart, country, brand, indication, segment, target, quarter]);

  const [background, setBackground] = useState({
    label: "12 Weeks - In Field Team",
    text: "12 Weeks - In Field Team",
    category: "In Field Team",
    code: "in_field_team_12.png",
  });

  const onIconChange = (event) => {
    console.log("parent", event);
    // setNodeImage(event.source);
    setBackground(event);
  };

  const onSave = async () => {
    const thumb = await htmlToImage.toJpeg(
      document.getElementById("backgroundID"),
      {
        quality: 0.8,
        backgroundColor: "#FFFFFF",
        canvasWidth: 320,
        canvasHeight: 180,
        // width: 320,
        // height: 180,
      }
    );

    var data = {
      name: name,
      country: country,
      brand: brand,
      indication: indication,
      segment,
      target,
      quarter,
      values: JSON.stringify([
        {
          id: "abv_0",
          type: "background",
          data: { label: "Background Node", source: background.code },
          position: { x: 0, y: 0 },
          draggable: false,
          selectable: false,
          connectable: false,
        },
      ]),
      date: new Date().getTime(),
      thumbnail: thumb,
    };

    console.log(data);
    chartService.createChart(data).then((response) => {
      setChart({ ...response });
      setRedirect(true);
    });
  };

  if (redirect) {
    console.log("ready to redirect", chart);
    return (
      <Navigate
        to="/edit"
        state={{
          id: `${chart._id}`,
          name: `${chart.name}`,
          country: `${chart.country}`,
          brand: `${chart.brand}`,
          indication: `${chart.indication}`,
          segment: `${chart.segment}`,
          target: `${chart.target}`,
          chartData: `${chart.values}`,
        }}
      />
    );
  }

  return (
    <>
      <Nav onCreate={onSave} ready={ready}></Nav>
      <main>
        <Container maxWidth="xl" sx={{ mt: 4, mb: 2 }}>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} md={6} lg={8}>
              <Paper
                id="backgroundID"
                sx={{
                  p: 1,
                  display: "flex",
                  flexDirection: "column",
                  //   height: 240,
                }}
              >
                <img
                  src={`https://s1.myovn.com/abbvie/bg/${background.code}`}
                  width="100%"
                />
              </Paper>
            </Grid>
            {/* Chart */}
            <Grid item xs={12} lg={4}>
              <Paper
                sx={{
                  p: 3,
                  // display: "flex",
                  // flexDirection: "row",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Project Name"
                  variant="outlined"
                  value={name}
                  fullWidth
                  sx={{ mb: 2 }}
                  onChange={(e) => setName(e.target.value)}
                />

                <BackgroundSelect
                  onIconChange={onIconChange}
                  selected={background}
                ></BackgroundSelect>

                <ListSelect
                  list="country"
                  label="Country"
                  value={country}
                  onChange={(value) => setCountry(value)}
                ></ListSelect>

                <ListSelect
                  list="brand"
                  label="Product"
                  value={brand}
                  onChange={(value) => {
                    setIndication(null);
                    setBrand(value);
                  }}
                ></ListSelect>

                <ListSelect
                  list="indication"
                  label="Indication1"
                  brand={brand}
                  disabled={brand ? false : true}
                  value={indication}
                  onChange={(value) => setIndication(value)}
                ></ListSelect>

                <ListSelect
                  list="segment"
                  label="Segment"
                  value={segment}
                  onChange={(value) => setSegment(value)}
                ></ListSelect>

                <ListSelect
                  list="target"
                  label="Target"
                  value={target}
                  onChange={(value) => setTarget(value)}
                ></ListSelect>
                <ListSelect
                  list="quarter"
                  label="Quarter"
                  value={quarter}
                  onChange={(value) => setQuarter(value)}
                ></ListSelect>
              </Paper>
            </Grid>
          </Grid>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              "& button": { m: 1 },
            }}
          >
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={onSave}
              disabled={name == ""}
            >
              Create project
            </Button>
          </Box> */}
        </Container>
      </main>
    </>
  );
};
export default CreateNew;
