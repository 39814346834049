import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function BackgroundSelect(props) {
  const [flag, setFlag] = useState("");
  const [categ, setCateg] = useState("");

  const onChange = (event, value) => {
    console.log(value);
    if (value?.code) {
      setFlag(value?.code);
      setCateg(value?.category);
      //   const source = `https://s1.myovn.com/abbvie/icons/${value.code}`;
      //   const value2 = { ...value, source };
      props.onIconChange(value);
    }
  };

  useEffect(() => {
    if (props.selected?.category !== categ) {
      setCateg(props?.selected?.category);
    }
  }, [props.selected]);

  return (
    <Autocomplete
      id="icon-select"
      // sx={{ width: 300 }}
      sx={{ mb: 3 }}
      options={icons}
      value={props.selected}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      onChange={(e, v) => onChange(e, v)}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 1, flexShrink: 0 } }}
          {...props}
        >
          {/* <img
            loading="lazy"
            width="40"
            src={`https://s1.myovn.com/abbvie/icons/${option.code}`}
            // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          /> */}
          {option.label}
        </Box>
      )}
      renderInput={(params, data) => {
        // console.log(params);
        // console.log(data);

        return (
          <div
            style={{
              position: "relative",
            }}
          >
            {params.inputProps.value && (
              <>
                <span
                  style={{
                    position: "absolute",
                    marginLeft: "20px",
                    fontSize: 8,
                    color: "#666",
                    bottom: 5,
                  }}
                >
                  {categ}
                </span>
              </>
            )}
            <TextField
              {...params}
              label="Select Background"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
                style: { paddingLeft: "10px" }, // disable autocomplete and autofill
              }}
            />
          </div>
        );
      }}
    />
  );
}

const icons = [
  {
    label: "12 Weeks - In Field Team",
    text: "12 Weeks - In Field Team",
    category: "In Field Team",
    code: "in_field_team_12.png",
  },
  {
    label: "3 Months - In Field Team",
    text: "3 Months - In Field Team",
    category: "In Field Team",
    code: "in_field_team_3.png",
  },

  {
    label: "12 Weeks - Medical Team",
    text: "12 Weeks - Medical Team",
    category: "Medical Team",
    code: "medical_team_12.png",
  },
  {
    label: "3 Months - Medical Team",
    text: "3 Months - Medical Team",
    category: "Medical Team",
    code: "medical_team_3.png",
  },
];
