import React, { useEffect, useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ListContext } from "../services/ListContext";
// props: value, list, onchange, label
const ListSelect = (props) => {
  // const [value, setValue] = useState(props.value);
  const [list, setList] = useState(null);

  var listdata = React.useContext(ListContext);

  useEffect(() => {
    // console.log(props.list);
    if (Array.isArray(props.list)) {
      setList(props.list);
    } else {
      setList(listdata[props.list]);
    }

    // console.log("listdata_single", props.list, listdata[props.list]);
  }, []);

  let options = null;

  if (list) {
    if (props.brand) {
      options = list
        .filter((item) => item.brand === props.brand)
        .map((el) => {
          return el.label;
        });
      // console.log("options", options);
    }

    return (
      <Autocomplete
        disablePortal
        // freeSolo={props.brand ? true : false}
        id={`auto_${props.label}`}
        value={props.value}
        // filterSelectedOptions
        onChange={(event, newValue) => {
          // setValue(newValue);
          props.onChange(newValue);
        }}
        disabled={props.disabled === true ? true : false}
        options={!props.brand ? list : options}
        fullWidth
        sx={{ mt: 2 }}
        renderInput={(params) => <TextField {...params} label={props.label} />}
      />
    );
  }

  return null;
};
export default ListSelect;
