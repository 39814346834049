import React, { memo } from "react";
import styled from "styled-components";

import { Handle } from "react-flow-renderer";

const NodeWrapper = styled.div`
  width: 185px;
  /* height: 75px; */
  border-radius: ${({ radius }) => `${radius}px`};
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.06),
    0px 3px 4px 0px rgba(0, 0, 0, 0.06), 0px 1px 8px 0px rgba(0, 0, 0, 0.06);
  background: ${({ background }) => background};

  padding: 4px 6px;
  display: flex;
  align-items: center;
`;

const GlobalWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const LeftWrapper = styled.div`
  width: 84px;
  height: 84px;
  /* background: ${({ backgroundColor }) => backgroundColor}; */
  /* border-radius: 6px; */
  background: ${({ source }) => `url(${source}) no-repeat left top`};
  background-size: 160%;
  background-position: 50%;
  flex: 0 0 84px;
`;

const RightWrapper = styled.div`
  flex: 1 1 auto;
  text-align: left;
  margin-left: 4px;
  p {
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 500;
  }
  .start-when {
    text-transform: uppercase;
    color: #999094;
    font-size: 8px;
    /* letter-spacing: 2px; */
    letter-spacing: 0.1px;
    line-height: 12px;
  }
  .desc-main {
    color: #3d3a3b;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1px;
    white-space: pre-line;
  }
  .desc-caption {
    color: ${({ accentColor }) => accentColor};
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1px;
    font-weight: 400;
  }
`;

export default memo(({ data }) => {
  //   console.log("data", data);
  const handleStyle = {
    backgroundColor: "#fff",
    backgroundColor: "#e8e8e8",
    width: 22,
    height: 22,
    borderRadius: 12,
    border: `1px solid ${data.color}`,
  };

  return (
    <NodeWrapper radius={data.radius} background={data.background}>
      <Handle
        type="target"
        position="left"
        style={{
          ...handleStyle,
          //   top: -7,
          left: -11,
        }}
        onConnect={(params) => console.log("handle onConnect", params)}
      />
      <GlobalWrapper>
        <LeftWrapper
          backgroundColor={data.backgroundColor}
          source={`https://s1.myovn.com/abbvie/icons/${data.icon?.code}`}
        >
          {/* <img
            src={data.source}
            style={{ width: "100%", height: "auto" }}
            alt="i"
          />{" "} */}
        </LeftWrapper>
        <RightWrapper accentColor={data.color}>
          <p className="start-when">{data.icon?.text}</p>
          <p className="desc-main">{data.label}</p>
          {/* <p className="desc-caption">1 instance per contact</p> */}
        </RightWrapper>
      </GlobalWrapper>

      <Handle
        type="source"
        position="right"
        id="a"
        style={{
          ...handleStyle,
          //   left: 250 / 2 - 7,
          //   right: "auto",
          //   bottom: -7,
          right: -11,
        }}
      />
      {/* <Handle
        type="source"
        position="bottom"
        id="b"
        style={{
          ...handleStyle,
          right: 250 / 4 - 7,
          left: "auto",
          bottom: -7,
        }}
      /> */}
    </NodeWrapper>
  );
});
