import React, { useState, useEffect, createContext } from "react";
import { Redirect, useNavigate } from "react-router-dom";
import { isAuthenticated } from "./auth.service";

import SignIn from "../App";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const navigate = useNavigate();

  let provizoriu = {
    token:
      "4fcf3f78138f61fe6273dbd1d6c05f15e30496d7a085919bbcdcf11f271dcecfb564cd09ae8877ede8f0b29ede2a1cbf04de20a3b81e8b5f942704e99706889e94b7594bf85ca12dee93dc0970c79a62e91035dbfaf503dbcefdb1a49eae1983255a39f0ee303f4478af5967cac41a223a440c0862e367d4cfe9fd5d1ec2a8ae7a4cf867ca61706ee9615935d5c1a359169390bb872e5cfc84f3ab74b8190cd71718e9cb011b32de9692b92789dcdcfc62cc21f168650ee03cc557a2bb056bca",
    user: {
      _id: "5aa1c2c35ef7a4e97b5e995a",
      name: "Super Administrator",
      email: "admin@admin.com",
      role: "admin",
      verified: true,
      verification: "3d6e072c-0eaf-4239-bb5e-495e6486148f",
    },
  };
  localStorage.setItem("user", JSON.stringify(provizoriu));

  useEffect(() => {
    const checkLoggedIn = async () => {
      let cuser = isAuthenticated();
      if (!cuser.token) {
        console.log("custom token set");
        // cuser =

        // cuser = "";
        navigate("/");
      }

      setCurrentUser(cuser);
    };

    checkLoggedIn();
  }, []);

  console.log("usercontext", currentUser);

  return (
    <UserContext.Provider value={[currentUser, setCurrentUser]}>
      {/* {currentUser?.token ? children : null} */}
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
