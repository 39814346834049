import React, { useState, useEffect, createContext } from "react";

export const ListContext = createContext();

export const ListProvider = ({ children }) => {
  const [country, setCountry] = useState(null);
  const [brand, setBrand] = useState(null);
  const [indication, setIndication] = useState(null);
  const [target, setTarget] = useState(null);
  const [segment, setSegment] = useState(null);
  const [quarter, setQuarter] = useState(null);
  const [ready, setReady] = useState(false);

  let currentYear = new Date().getFullYear();
  let nextYear = new Date().getFullYear() + 1;
  let q = ["C1", "C2", "C3", "C4"];
  let list = [];

  useEffect(() => {
    fetch("data/listCountry.json")
      .then((res) => res.json())
      .then((data) => {
        setCountry(data);
      });

    fetch("data/listBrand.json")
      .then((res) => res.json())
      .then((data) => {
        setBrand(data);
      });

    fetch("data/listIndication.json")
      .then((res) => res.json())
      .then((data) => {
        setIndication(data);
      });
    fetch("data/listTarget.json")
      .then((res) => res.json())
      .then((data) => {
        setTarget(data);
      });
    fetch("data/listSegment.json")
      .then((res) => res.json())
      .then((data) => {
        setSegment(data);
      });

    q.map((item) => {
      list.push(item + " " + currentYear);
    });

    q.map((item) => {
      list.push(item + " " + nextYear);
    });

    setQuarter(list);
    // setReady(true);
  }, []);

  useEffect(() => {
    if (
      country != null &&
      brand != null &&
      indication != null &&
      segment != null &&
      target != null &&
      quarter != null
    ) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [country, brand, indication, segment, target, quarter]);

  //   console.log(
  //     "listcontext",
  //     country,
  //     brand,
  //     indication,
  //     target,
  //     segment,
  //     quarter
  //   );
  console.log("ready", ready);

  return (
    <ListContext.Provider
      value={{
        country: country,
        brand: brand,
        indication: indication,
        target: target,
        segment: segment,
        quarter: quarter,
        ready: ready,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

export default ListProvider;
