import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function IconSelect(props) {
  const [flag, setFlag] = useState("");
  const [categ, setCateg] = useState("");

  const onChange = (event, value) => {
    console.log(value);
    if (value?.code) {
      setFlag(value?.code);
      setCateg(value?.category);
      //   const source = `https://s1.myovn.com/abbvie/icons/${value.code}`;
      //   const value2 = { ...value, source };
      props.onIconChange(value);
    }
  };

  useEffect(() => {
    if (props.selected?.category !== categ) {
      setCateg(props?.selected?.category);
      setFlag(props?.selected?.code);
    }
  }, [props.selected]);

  return (
    <Autocomplete
      id="icon-select"
      fullWidth
      options={icons}
      value={props.selected}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      onChange={(e, v) => onChange(e, v)}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 1, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="40"
            src={`https://s1.myovn.com/abbvie/icons/${option.code}`}
            // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      renderInput={(params, data) => {
        // console.log(params);
        // console.log(data);

        return (
          <div
            style={{
              position: "relative",
            }}
          >
            {params.inputProps.value && (
              <>
                <span
                  style={{
                    position: "absolute",
                    transform: "translateY(50%)",
                    marginLeft: "10px",
                  }}
                >
                  <img
                    loading="lazy"
                    width="40"
                    src={`https://s1.myovn.com/abbvie/icons/${flag}`}
                    alt=""
                  />
                </span>
                <span
                  style={{
                    position: "absolute",
                    marginLeft: "64px",
                    fontSize: 8,
                    color: "#666",
                    bottom: 5,
                  }}
                >
                  {categ}
                </span>
              </>
            )}
            <TextField
              {...params}
              label="Choose Icon"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
                style: { paddingLeft: "55px" }, // disable autocomplete and autofill
              }}
            />
          </div>
        );
      }}
    />
  );
}

const icons = [
  {
    label: "AbbVie Care Services Booklet",
    text: "AbbVie Care Services Booklet",
    category: "Abbvie Care",
    code: "AbbVie%20Care%20Services%20Booklet.png",
  },
  {
    label: "Call Center",
    text: "Call Center",
    category: "Abbvie Care",
    code: "Call%20center.png",
  },
  {
    label: "Care Coach",
    text: "Care Coach",
    category: "Abbvie Care",
    code: "Care%20Coach.png",
  },
  {
    label: "Companion",
    text: "Companion",
    category: "Abbvie Care",
    code: "Companion.png",
  },

  {
    label: "Habit Formation Guide",
    text: "Habit Formation Guide",
    category: "Abbvie Care",
    code: "Habit%20Formation%20guide.png",
  },

  {
    label: "HCP Feedback Loop",
    text: "HCP Feedback Loop",
    category: "Abbvie Care",
    code: "HCP%20Feedback%20Loop.png",
  },

  {
    label: "Insight gathering activity",
    text: "Insight gathering activity",
    category: "Abbvie Care",
    code: "Patient%20Conversation%20Cards.png",
  },

  {
    label: "Intro booklet and Video",
    text: "Intro booklet and Video",
    category: "Abbvie Care",
    code: "Intro%20booklet%20and%20Video.png",
  },

  {
    label: "Other communication",
    text: "Other communication",
    category: "Abbvie Care",
    code: "Other%20communication.png",
  },
  {
    label: "Patient Conversation Cards",
    text: "Patient Conversation Cards",
    category: "Abbvie Care",
    code: "Patient%20Conversation%20Cards.png",
  },

  {
    label: "Patient website",
    text: "Patient website",
    category: "Abbvie Care",
    code: "Patient%20website.png",
  },
  {
    label: "PIL_QR code website",
    text: "PIL_QR code website",
    category: "Abbvie Care",
    code: "PIL_QR%20code%20website.png",
  },

  {
    label: "CLM",
    text: "CLM",
    category: "Commercial",
    code: "commercial/CLM.png",
  },

  {
    label: "Congress Digital Tactic",
    text: "Congress Digital Tactic",
    category: "Commercial",
    code: "commercial/Congress%20Digital%20Tactic.png",
  },
  {
    label: "Congress Live Tactic",
    text: "Congress Live Tactic",
    category: "Commercial",
    code: "commercial/Congress%20Live%20Tactic.png",
  },
  {
    label: "Digital Peer to Peer",
    text: "Digital Peer to Peer",
    category: "Commercial",
    code: "commercial/Digital%20Peer%20to%20Peer.png",
  },
  {
    label: "Insight gathering activity",
    text: "Insight gathering activity",
    category: "Commercial",
    code: "commercial/Insight%20gathering%20activity.png",
  },
  {
    label: "Live Peer to Peer",
    text: "Live Peer to Peer",
    category: "Commercial",
    code: "commercial/Live%20Peer%20to%20Peer.png",
  },
  {
    label: "Paid media",
    text: "Paid media",
    category: "Commercial",
    code: "commercial/Paid%20media.png",
  },
  {
    label: "Publications",
    text: "Publications",
    category: "Commercial",
    code: "commercial/Publications.png",
  },
  {
    label: "SMS",
    text: "SMS",
    category: "Commercial",
    code: "commercial/SMS.png",
  },
  {
    label: "Training",
    text: "Training",
    category: "Commercial",
    code: "commercial/Training.png",
  },
  {
    label: "Web",
    text: "Web",
    category: "Commercial",
    code: "commercial/Web.png",
  },

  {
    label: "Congress presentation",
    text: "Congress presentation",
    category: "Medical Affairs",
    code: "medical_affairs/Congress%20presentation.png",
  },
  {
    label: "Data Evidence",
    text: "Data Evidence",
    category: "Medical Affairs",
    code: "medical_affairs/Data%20Evidence.png",
  },
  {
    label: "Insight gathering activity",
    text: "Insight gathering activity",
    category: "Medical Affairs",
    code: "medical_affairs/Insight%20gathering%20activity.png",
  },
  {
    label: "Live Training",
    text: "Live Training",
    category: "Medical Affairs",
    code: "medical_affairs/Live%20Training.png",
  },
  {
    label: "Manuscript",
    text: "Manuscript",
    category: "Medical Affairs",
    code: "medical_affairs/Manuscript.png",
  },
  {
    label: "MSL Material",
    text: "MSL Material",
    category: "Medical Affairs",
    code: "medical_affairs/MSL%20Material.png",
  },
  {
    label: "Other activity",
    text: "Other activity",
    category: "Medical Affairs",
    code: "medical_affairs/Other%20activity.png",
  },
  {
    label: "Virtual Training",
    text: "Virtual Training",
    category: "Medical Affairs",
    code: "medical_affairs/Virtual%20Training.png",
  },
  {
    label: "Animation",
    text: "Animation",
    category: "Medical data topics",
    code: "medical_data/Animation.png",
  },
  {
    label: "Data Visualization Tool",
    text: "Data Visualization Tool",
    category: "Medical data topics",
    code: "medical_data/Data%20Visualization%20Tool.png",
  },
  {
    label: "EE-Led Video",
    text: "EE-Led Video",
    category: "Medical data topics",
    code: "medical_data/EE-Led%20Video.png",
  },
  {
    label: "Infographic",
    text: "Infographic",
    category: "Medical data topics",
    code: "medical_data/Infographic.png",
  },
  {
    label: "Interactive Medical Aid (IMA)",
    text: "Interactive Medical Aid (IMA)",
    category: "Medical data topics",
    code: "medical_data/Interactive%20Medical%20Aid%20%28IMA%29.png",
  },
  {
    label: "Manuscript Video",
    text: "Manuscript Video",
    category: "Medical data topics",
    code: "medical_data/Manuscript%20Video.png",
  },
  {
    label: "Manuscript",
    text: "Manuscript",
    category: "Medical data topics",
    code: "medical_data/Manuscript.png",
  },
  {
    label: "Mixed Reality.png",
    text: "Mixed Reality.png",
    category: "Medical data topics",
    code: "medical_data/Mixed%20Reality.png",
  },
  {
    label: "MOA_MOD_Video",
    text: "MOA_MOD_Video",
    category: "Medical data topics",
    code: "medical_data/MOA_MOD_Video.png",
  },
  {
    label: "Podcast",
    text: "Podcast",
    category: "Medical data topics",
    code: "medical_data/Podcast.png",
  },
  {
    label: "Slide Deck",
    text: "Slide Deck",
    category: "Medical data topics",
    code: "medical_data/Slide%20Deck.png",
  },
];
